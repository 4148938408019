import React from 'react'

const Processing = () => {
  const styles = {
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    background: "linear-gradient(90deg, #FFCC29 0%, #E49700 100%)",
    color: "#ffffff",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "calc(50% - 2rem)",
    left: "calc(50% - 4rem)",
  };

  return (
    <div
    style={{position: 'relative'}}
      className="d-flex justify-content-center"
    >
      <div style={{...styles, width: 150, backgroundColor: 'white', position: 'absolute', top: 100}}>Processing...</div>
    </div>
  );
};

export default Processing;
