import axios from "axios";
// base url for API call
// const Base_URL = "http://192.168.29.162:3000/api/v1/user";
const Base_URL = "https://db.honeys.gold:3000/api/v1/user";
// const Base_URL = "http://138.68.157.244:3000/api/v1/user";

// common method for API call
export const fetchDataFromAPI = (url, method, body, token) =>
  new Promise((resolve, reject) => {
    // console.debug(
    //   "----------------APICAll(fetchDataFromAPI)------------------------"
    // );
    // console.log("Url", Base_URL + url);
    // console.log("method", method);
    // console.log("Body", body);
    // console.log("Token", token);

    var headers;

    if (token != null) {
      headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {
        Accept: "application/json",
      };
    }

    axios({
      method: method,
      baseURL: Base_URL + url,
      headers: headers,
      data: body,
    })
      .then((response) => {
        // When api send success response set in resolve method
        //   console.log("check main response",response)
        return resolve(response.data);
      })
      .catch((error) => {
        // When api send success response set in resolve method
        return reject(error);
      });
  });
