import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Processing from "../../components/processing/processing";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const UserDetails = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state;

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);
    formik.setFieldValue("username", user?.username);
    formik.setFieldValue("password", user?.password);
    formik.setFieldValue("email", user?.email);
    formik.setFieldValue("comment", user?.comment);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please Enter Username"),
    password: Yup.string()
      .min(8, ({ min }) => `Minimum ${min} characters required.`)
      .required("Please Enter Password"),
    email: Yup.string().required("Please Enter email"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      email: "",
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
      const body = {
        id: user?._id,
        username: values?.username,
        password: values?.password,
        email: values?.email,
        com: values?.comment,
      };
      fetchDataFromAPI("/admin/edit", "post", body, "")
        .then((res) => {
          console.log("res: ", res);
          toast.success("User Details updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          navigate("/");
        })
        .catch((error) => {
          toast.error("Error while updating User Details", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error: ", error);
        });
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="dsa__advice pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">User Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column mt-3">
          <label className="category_label">User Name</label>
          <input
            type="text"
            name="username"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            // {...formik.getFieldProps("username")}
            value={formik.values.username}
            onChange={(e) => {
              formik.setFieldValue("username", e.target.value?.trimStart())
            }}
          />
          {formik.touched.username && formik.errors.username && (
            <div className="text-danger">{formik.errors.username}</div>
          )}
        </div>
        <div className="d-flex flex-column mt-3">
          <label className="category_label">Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              disabled={isEditable ? false : true}
              className="form form-control bg-transparent text-black type_password"
              // {...formik.getFieldProps("password")}
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value?.trimStart())
              }}
            />
            {isEditable && (
              <div
                className="eye-icon text-secondary"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </div>
            )}
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Email</label>
          <input
            type="text"
            name="email"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black type_password"
            // {...formik.getFieldProps("email")}
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value?.trimStart())
            }}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Note</label>
          <textarea
            type="text"
            disabled={isEditable ? false : true}
            name="comment"
            aria-multiline="true"
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("comment")}
          />
          {formik.touched.comment && formik.errors.comment && (
            <div className="text-danger">{formik.errors.comment}</div>
          )}
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button btn advice__category"
            disabled={
              buttonValue === "Update" &&
              formik.values.username === user?.username &&
              formik.values.password === user?.password &&
              formik.values.email === user.email &&
              formik.values.comment === user?.comment
            }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
