import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Processing from "../../components/processing/processing";
import { useLocation } from "react-router-dom";
import moment from "moment";

const LogbookDetails = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { log } = state;

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);

    if (log?.type === "Login") {
      formik.setFieldValue("userName", log?.userName);
      formik.setFieldValue("type", log?.type);
      formik.setFieldValue("params", log?.params);
      formik.setFieldValue("date_time", log?.createdAt);
      formik.setFieldValue("response", log?.response);
    } else {
      var jsonparams = log?.params;
      var jsonResponse = log?.response;
      if (jsonparams) {
        var params = JSON.parse(jsonparams);
      }
      if (jsonResponse) {
        var objects = jsonResponse?.toString()?.match(/[^{}]+(?=})/g);
      }
      var formattedParams = "";
      for (var key in params) {
        formattedParams += key + ": " + JSON.stringify(params[key]) + ",\n";
      }

      var formattedResponse = "";
      for (var i = 0; i < objects?.length && i < objects?.length; i++) {
        var objectString = objects[i];

        // Parse the object string into an actual object
        var response = JSON.parse("{" + objectString + "}");

        // Perform the desired action
        for (var key in response) {
          formattedResponse +=
            key + ": " + JSON.stringify(response[key]) + ",\n";
        }
      }

      formik.setFieldValue("userName", log?.userName);
      formik.setFieldValue("type", log?.type);
      formik.setFieldValue(
        "date_time",
        moment(log?.createdAt).local().format("DD-MM-YYYY hh:mm a")
      );
      formik.setFieldValue("params", formattedParams);
      formik.setFieldValue("response", formattedResponse);
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      type: "",
      params: "",
      date_time: "",
      response: "",
    },
  });

  return (
    <div className="dsa__advice pb-5">
      {/* {loading && <Processing />} */}
      {/* header */}
      <div className="title">User Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column mt-3">
          <label className="category_label">Name</label>
          <input
            type="text"
            name="userName"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("userName")}
          />
          {/* {formik.touched.username && formik.errors.username && (
            <div className="text-danger">{formik.errors.username}</div>
          )} */}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Type</label>
          <input
            type="text"
            name="type"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("type")}
          />
          {/* {formik.touched.email && formik.errors.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )} */}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Params</label>
          <textarea
            type="text"
            disabled={isEditable ? false : true}
            name="params"
            aria-multiline="true"
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("params")}
          />
          {/* {formik.touched.comment && formik.errors.comment && (
            <div className="text-danger">{formik.errors.comment}</div>
          )} */}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Date & Time</label>
          <textarea
            type="text"
            disabled={isEditable ? false : true}
            name="date_time"
            aria-multiline="true"
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("date_time")}
          />
          {/* {formik.touched.comment && formik.errors.comment && (
            <div className="text-danger">{formik.errors.comment}</div>
          )} */}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Logs</label>
          <textarea
            type="text"
            disabled={isEditable ? false : true}
            name="response"
            aria-multiline="true"
            className="form form-control bg-transparent text-black"
            {...formik.getFieldProps("response")}
          />
          {/* {formik.touched.comment && formik.errors.comment && (
            <div className="text-danger">{formik.errors.comment}</div>
          )} */}
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogbookDetails;
