import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error-container">
      <h1 className="fw-bolder fs-2hx text-light mb-4">Oops!</h1>
      <div className="fw-semibold fs-6 text-white mb-7">
        We can't find this page.
      </div>
      <div className="mb-0">
        <Link onClick={() => window.history.back()} className="btn btn-sm btn-primary mt-3">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default Error;
