import React, { useEffect, useState } from "react";
import "./css/style.css";
import routes from "./routes/route";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Error from "./pages/error/error";
import Sidebar from "./components/sidebar/Sidebar";
import constants from "./utils/constants";
import { ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

// main component
function App() {
  const navigate = useNavigate();
  const currentPath = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const USER = localStorage.getItem(constants.USER);

  useEffect(() => {
    checkSessionExpiration();
  });

  const checkSessionExpiration = () => {
    const lastLoginTime = localStorage.getItem(constants.LAST_LOGIN_TIME);
    if (lastLoginTime) {
      const currentTime = new Date().getTime();
      const sessionExpirationTime = 30 * 60 * 1000; // 1 minutes in milliseconds

      if (currentTime - lastLoginTime > sessionExpirationTime) {
        localStorage.clear();
        handleShow();
        navigate("/login");
        // Perform logout actions
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {USER && currentPath.pathname !== "/404" && <Sidebar />}
      <Routes>
        {routes.map(({ path, element, title }) => (
          <Route key={title} path={path} element={element} />
        ))}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        {/* <Route path="/" element={<Navigate to="/users" replace />} /> */}
      </Routes>

      {/* session expire modal */}
      <Modal className="" show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="session_bg border_class text-white"
        >
          <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body className="session_bg text-white">
          Your Session has expired! Re-login again to continue.
        </Modal.Body>
        <Modal.Footer className="session_bg border_class">
          <Button className="button_bg" onClick={handleClose}>
            Ok
          </Button>
          {/* <Button variant="danger">Delete</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
