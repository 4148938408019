import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const UserModal = (props) => {
  
  const [show1, setShow1] = useState(false);
  
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required("Please Enter Username"),
    email: Yup.string().email('Please enter valid email').trim().required("Please Enter email"),
    password: Yup.string().trim().min(8, ({ min }) => `Must be at least 8 characters.`).required("Please enter Password")
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
      const body = {
        username: values?.username,
        email: values?.email,
        password: values?.password,
        com: values?.comment
      }
      fetchDataFromAPI("/register", "post", body, "")
        .then(res => {
          console.log("res: ", res);
          toast.success("User Added successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          formik.resetForm();
          props?.onHide();
          props?.onClose();
          document.querySelector('#close__button')?.click();
          // window.location.reload()
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log("error: ", error);
          } else {
            toast.error("Error while Adding User Details", {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log("error: ", error);
          }
        })
    },
  });


  return (
    <>
        <Modal {...props} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Users</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-column mt-3">
              <label className="category_label">Name</label>
              <input
                type="text"
                name="Name"
                placeholder="Enter Name"
                className="form form-control bg-transparent text-black add_inputs"
                // {...formik.getFieldProps("username")}
                value={formik.values.username}
                onChange={(e) => {
                  formik.setFieldValue("username", e.target.value?.trimStart())
                }}
              />
              {formik.touched.username && formik.errors.username && (
                <div className="text-danger">{formik.errors.username}</div>
              )}
            </div>
            <div className="d-flex flex-column mt-3">
              <label className="category_label">Email</label>
              <input
                type="email"
                name="email"
                // {...formik.getFieldProps("email")}
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value?.trimStart())
                }}
                placeholder="Enter Email"
                className="form form-control bg-transparent text-black add_inputs"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
            <div className="d-flex flex-column mt-3">
              <label className="category_label">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                className="form form-control bg-transparent text-black add_inputs"
                // {...formik.getFieldProps("password")}
                value={formik.values.password}
                onChange={(e) => {
                  formik.setFieldValue("password", e.target.value?.trimStart())
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="text-danger">{formik.errors.password}</div>
              )}
            </div>
            <div className="d-flex flex-column mt-3">
              <label className="category_label">Note</label>
              <textarea
                type="text"
                name="Note"
                placeholder="Enter User Note"
                aria-multiline="true"
                className="form form-control bg-transparent text-black add_inputs"
                {...formik.getFieldProps("comment")}
              />
              {formik.touched.comment && formik.errors.comment && (
                <div className="text-danger">{formik.errors.comment}</div>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              variant="secondary"
              className="btn btn-primary"
              //   data-bs-dismiss="modal"
              onClick={() => {
                formik.resetForm();
                // formik.setFieldValue("category", categories[0]?.id)
              }}
            >
              Clear
            </button>
            <button type="button" onClick={formik.handleSubmit} className="btn secondary_bg advice__category text-white">
              Add
            </button>
          </Modal.Footer>
        </Modal>
      

      {/* <div
        id="userModal"
        className="modal modal-content-scrollable"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header text-black border-0">
              <h5 className="modal-title">Add Users</h5>
              <button
                type="button"
                className="btn-close btn-close-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close__button"
                onClick={() => formik.resetForm()}
              ></button>
            </div>
            <div className="modal-body">


              <div className="d-flex flex-column mt-3">
                <label className="category_label">Name</label>
                <input
                  type="text"
                  name="Name"
                  placeholder="Enter Name"
                  className="form form-control bg-transparent text-black add_inputs"
                  // {...formik.getFieldProps("username")}
                  value={formik.values.username}
                  onChange={(e) => {
                    formik.setFieldValue("username", e.target.value?.trimStart())
                  }}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className="text-danger">{formik.errors.username}</div>
                )}
              </div>
              <div className="d-flex flex-column mt-3">
                <label className="category_label">Email</label>
                <input
                  type="email"
                  name="email"
                  // {...formik.getFieldProps("email")}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value?.trimStart())
                  }}
                  placeholder="Enter Email"
                  className="form form-control bg-transparent text-black add_inputs"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </div>
              <div className="d-flex flex-column mt-3">
                <label className="category_label">Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  className="form form-control bg-transparent text-black add_inputs"
                  // {...formik.getFieldProps("password")}
                  value={formik.values.password}
                  onChange={(e) => {
                    formik.setFieldValue("password", e.target.value?.trimStart())
                  }}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="text-danger">{formik.errors.password}</div>
                )}
              </div>
              <div className="d-flex flex-column mt-3">
                <label className="category_label">Note</label>
                <textarea
                  type="text"
                  name="Note"
                  placeholder="Enter User Note"
                  aria-multiline="true"
                  className="form form-control bg-transparent text-black add_inputs"
                  {...formik.getFieldProps("comment")}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <div className="text-danger">{formik.errors.comment}</div>
                )}
              </div>
            </div>
            <div className="modal-footer border-0 ">
              <button
                type="button"
                className="btn btn-primary border-0"
                //   data-bs-dismiss="modal"
                onClick={() => {
                  formik.resetForm();
                  // formik.setFieldValue("category", categories[0]?.id)
                }}
              >
                Clear
              </button>
              <button type="button" onClick={formik.handleSubmit} className="btn secondary_bg advice__category text-white">
                Add
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default UserModal;
