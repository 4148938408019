import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { json, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserModal from "../../components/userModal/UserModal";
import LogBookModal from "../../components/logbookModal/LogBookModal";
import { Button, Modal } from "react-bootstrap";
import { fetchDataFromAPI } from "../../utils/api";
import constants from "../../utils/constants";
import { BsFillEyeFill } from "react-icons/bs";
import Processing from "../../components/processing/processing";
import moment from "moment";

const LogTable = (props) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = () => {
    setLoading(true);
    const user = localStorage.getItem(constants.USER);
    // console.log("user: ", users);
    const token = JSON.parse(user);
    // console.log("token: ", token?.token);

    fetchDataFromAPI("/admin/logs", "get", "", token?.token)
      .then((response) => {
        setLoading(false);
        console.log("logs response: ", response);
        console.log("response: ", response.data[0]);
        setLogs(response?.data?.reverse());
      })
      .catch((error) => {
        setLoading(false);
        // console.log("logs error: ", error);
        if (error?.response?.data?.message === "UnAuthorized") {
          localStorage.removeItem(constants.USER);
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        {/* header */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Logs</div>
        </div>

        {/* list */}
        <div className="user__list logbook_list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Types</th>
                <th className="text-center">Date & Time</th>
                <th className="text-center">Params</th>
                <th className="text-center">Log</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {logs?.length !== 0 ? (
                logs?.map((log) => {
                  if (log?.type !== "Login") {
                    var jsonparams = log?.params;
                    var jsonResponse = log?.response;
                    if (jsonparams) {
                      var params = JSON.parse(jsonparams);
                    }
                    if (jsonResponse) {
                      var objects = jsonResponse?.toString()?.match(/[^{}]+(?=})/g);
                    }

                    var formattedParams = "";
                    for (var key in params) {
                      formattedParams +=
                        key + ": " + JSON.stringify(params[key]) + ",\n";
                    }

                    var formattedResponse = "";
                    for (
                      var i = 0;
                      i < objects?.length && i < objects?.length;
                      i++
                    ) {
                      var objectString = objects[i];

                      // Parse the object string into an actual object
                      var response = JSON.parse("{" + objectString + "}");

                      // Perform the desired action
                      for (var key in response) {
                        formattedResponse +=
                          key + ": " + JSON.stringify(response[key]) + ",\n";
                      }
                    }
                  }

                  return (
                    <tr key={log?._id}>
                      <td className="text-center">
                        <h5>{log?.userName}</h5>
                      </td>
                      <td className="text-center">
                        <p>{log?.type}</p>
                      </td>
                      <td className="text-center col-1">
                        <p className="line2_content url_content">
                          {moment(log?.createdAt).local().format("DD-MM-YYYY hh:mm a")}
                        </p>
                      </td>
                      <td className="text-center col-3">
                        <p className="line2_content">
                          {log?.type !== "Login"
                            ? formattedParams
                            : log?.params}
                        </p>
                      </td>
                      <td className="text-center col-3">
                        <p className="line2_content">
                          {log?.type !== "Login"
                            ? formattedResponse
                            : log?.response}
                        </p>
                      </td>
                      <td className="col-1 text-center align-middle h-100">
                        <div className="d-flex gap-2  justify-content-center">
                          <span
                            onClick={() =>
                              navigate(`/logbook/${log?._id}`, {
                                state: { log: log },
                              })
                            }
                          >
                            <BsFillEyeFill />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-dark text-center" colSpan={6}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete the advice?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogTable;
