import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserModal from "../../components/userModal/UserModal";
import { Button, Modal } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { fetchDataFromAPI } from "../../utils/api";
import constants from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import Processing from "../../components/processing/processing";

const UserTable = (props) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    const user = localStorage.getItem(constants.USER);
    const token = JSON.parse(user)?.token;

    fetchDataFromAPI("/admin/userlist", "get", "", token)
      .then((response) => {
        setLoading(false);
        // console.log("users response: ", response);
        setUsers(response.data);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("users error: ", error.response);
        if (error?.response?.data?.message === "UnAuthorized") {
          localStorage.removeItem(constants.USER);
          navigate("/login");
        }
      });
  };

  const onClose = () => {
    getUsers();
  };

  const onDelete = () => {
    handleClose();
    setLoading(true);
    const body = {
      id: deleteId,
    };
    fetchDataFromAPI("/admin/delete", "post", body, "")
      .then((res) => {
        setLoading(false);
        console.log("res: ", res);
        toast.success("User Deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getUsers();
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error while Deleting User", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("error: ", error);
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        {/* header */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Users</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary" 
            onClick={() => setModalShow(true)}
          >
            Add
          </button>
           
        </div>
        <UserModal onClose={onClose} show={modalShow} onHide={() => setModalShow(false)}/>
        {/* <AddAdviceModal onAdd={() => getAdvice()} /> */}
        {/* list */}
        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Note</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {users?.filter((user) => user?.role !== "1")?.length !== 0 ? (
                users
                  ?.filter((user) => user?.role !== "1")
                  ?.map((user) => (
                    <tr key={user?._id}>
                      <td className="col-2">
                        <h5>{user?.username}</h5>
                      </td>
                      <td className="col-2">
                        <p>{user?.email}</p>
                      </td>
                      <td className="col-4">
                        <p className="note">{user?.comment || "-"}</p>
                      </td>
                      <td className="col-2 text-end align-middle h-100">
                        <div className="d-flex gap-2  justify-content-end">
                          <span
                            onClick={() =>
                              navigate(`/users/${user?._id}`, {
                                state: { user: user },
                              })
                            }
                          >
                            <BsFillEyeFill />
                          </span>
                          {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                          <div
                            className="delete_button"
                            onClick={() => {
                              setDeleteId(user?._id);
                              handleShow();
                            }}
                          >
                            <Delete />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="text-center text-dark" colSpan={4}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="dark"
          className="primary_bg bg-white border-0 text-black"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg bg-white text-black">
          Are you sure you want to delete the User?
        </Modal.Body>
        <Modal.Footer className="primary_bg bg-white border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserTable;
