import LoginPage from "../pages/login/LoginPage";
import UserTable from "../pages/users/UserTable";
import LogbookTable from "../pages/logbook/LogbookTable";
import PrivateRoute from "./routeProtection";
import UserDetails from "../pages/UserDetails/UserDetails";
import LogbookDetails from "../pages/logbookDetails/logbookDetails";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
    title: "Login",
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <UserTable />
      </PrivateRoute>
    ),
    title: "Users",
  },
  {
    path: "/users/:id",
    element: (
      <PrivateRoute>
        <UserDetails />
      </PrivateRoute>
    ),
    title: "Users",
  },
  {
    path: "/logbook",
    element: (
      <PrivateRoute>
        <LogbookTable />
      </PrivateRoute>
    ),
    title: "Logbook",
  },
  {
    path: "/logbook/:id",
    element: (
      <PrivateRoute>
        <LogbookDetails />
      </PrivateRoute>
    ),
    title: "Logbook",
  },
];

export default routes;
