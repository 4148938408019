import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-ir.png";
import bell from "../../assets/svgs/bell.svg";
import bars from "../../assets/svgs/toggle_bars.svg";
import profile from "../../assets/svgs/profile.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../../utils/constants";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { supabase } from "../../supabase/supabaseClient";
import { toast } from "react-toastify";
import LogOutModal from "../logOutModal/logOutModal";
// import { Modal } from "react-bootstrap";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const removeClass = (element) => {
    for (let i = 0; i <= 6; i++) {
      element[i]?.classList.remove("active");
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("users")[0]?.classList.add("active");
    } else if (pathname === "/logbook") {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("logbook")[0]?.classList.add("active");
    }
  }, [pathname]);

  const onClose = () => {
    navigate("/login");
  };

  return (
    <>
      <aside className="dsa__dashboard">
        <div className="dsa__dashboard-wrapper">
          <div className="dsa__dash-logo d-flex align-items-center">
            <img src={logo} alt="logo" className="w-25 h-25" />
            <p className="text-black fw-bold fs-5 m-0 ms-3">Honey Admin</p>
          </div>
          <ul className="dsa__dash-menu">
            {/* <li>
              <Link to="/advice" className="link advice">
                Advice
              </Link>
            </li> */}
            <li>
              <Link to="/" className="link users">
                Users
              </Link>
            </li>
            <li>
              <Link to="/logbook" className="link logbook">
                Log Book
              </Link>
            </li>
            <li>
              <Link
                className="link logout"
                // type="button"
                data-bs-toggle="modal"
                data-bs-target="#userModal5"
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <LogOutModal onClose={onClose} />

      <div className="dsa__dash-header">
        <div className="dsa__dash-header-wrapper">
          <div className="ra_dash-head_right">
            <div className="dsa__dash-head_profile">
              <Button onClick={handleShow}>
                <img src={bars} alt="toggle-btn" className="bars" />
              </Button>

              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="dsa__dashboard">
                    <div className="dsa__dashboard-wrapper">
                      <div className="dsa__dash-logo">
                        <img src={logo} alt="logo" />
                      </div>
                      <ul className="dsa__dash-menu">
                        {/* <li>
                          <Link
                            to="/advice"
                            className="link advice"
                            onClick={handleClose}
                          >
                            Advice
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to="/"
                            className="link users"
                            onClick={handleClose}
                          >
                            Users
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/logbook"
                            className="link logbook"
                            onClick={handleClose}
                          >
                            Log Book
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/login"
                            // type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#userModal5"
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
          {/* <div className="ra_dash-head_left">
            <div className="dsa__dash-head_profile">
              <Link to="">
                <img src={profile} alt="profile" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
